import IMask from 'imask';
import {FieldMaskLoader} from "./field_mask/field_mask_loader";
import './add_jquery';

var user_cpf_field = document.getElementById('user_document');

if (user_cpf_field) {
  FieldMaskLoader.setup_mask(document, 'user_document', 'cpf', IMask, true);
} else {
  FieldMaskLoader.setup_mask(document, 'candidate_document', 'cpf', IMask, true);
}

$(function() {
  $("body").on('click', '.toggle-password', function() {
    $(this).toggleClass("bi-eye-fill bi-eye-slash-fill");
    var input = $("#user_password,#candidate_password");
    if (input.attr("type") === "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });
})
