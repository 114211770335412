export class FieldMaskLoader {
  private static get_mask(type: string): string | {mask: string, overwrite: boolean}[] {
    enum types {cpf, phone}
    const masks = [
      '000.000.000-00',
      [
        { mask: '(00) 0000-0000', overwrite: true },
        { mask: '(00) 00000-0000', overwrite: true }
      ]
    ];

    return masks[types[type]];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static setup_mask(document: Document, id: string, type: string, IMask: any, laziness = false): void {
    const field = document.getElementById(id);
    const maskOptions = { mask: this.get_mask(type), lazy: laziness };
    IMask(field, maskOptions);
  }
}
